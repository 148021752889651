import "./teamSection.scss";

import instagramLogo from './socialIcons/Instagram_logo.png';

import { ArrowGraphic } from "../graphics/ArrowGraphic";
import { TikTok } from "./socialIcons/TikTok";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const TeamSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="main-page__team-section">
                <div className="main-page__team-section__headline main-page__team-section__headline--main">
                    <span>
                    Ucz się z nami i  zyskaj <b>całą wiedzę</b> na studia w jednym miejscu.
                    </span>
                </div>

                <div className="main-page__team-section__main">

                    <div className="main-page__team-section__main__left">

                        <div className="main-page__team-section__headline main-page__team-section__headline--second">
                            <span>
                                Ucz się z nami i zyskaj <b>całą wiedzę</b> na studia w jednym miejscu.
                            </span>
                        </div>

                        <div className="main-page__team-section__clickable__socials">
                                <div className="not-selectable main-page__team-section__clickable__socials__tik-tok">
                                    <a href="https://www.tiktok.com/@nasesje.com"><TikTok/></a>
                                </div>

                                <div className="not-selectable main-page__team-section__clickable__socials__instagram">
                                    <a href="https://www.instagram.com/nasesje/"><img src={instagramLogo} alt="" /></a>
                                </div>
                            <div className="main-page__team-section__clickable__socials__data">

                                <div className="main-page__team-section__clickable__socials__data__headline">
                                    <span>Co mówią o nas liczby:</span>
                                </div>

                                <div className="main-page__team-section__clickable__socials__data__stats">
                                    <div className="main-page__team-section__clickable__socials__data__stats__batch">
                                            <b>+ </b>
                                            <span>16K </span>
                                            <span className="material-symbols-outlined purple">
                                            person
                                            </span>
                                        </div>
                                    <div className="main-page__team-section__clickable__socials__data__stats__batch">
                                        <b>+ </b>
                                        <span>415K </span>
                                        <span className="material-symbols-outlined red">
                                            favorite
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>  
                        
                        <span className="main-page__team-section__main__left__text-1 desktop">
                            <b>Zapomnij o:</b> długich poszukiwaniach materiałów w sieci i kosztownych korepetycjach.
                        </span>
                        <span className="main-page__team-section__main__left__text-1 mobile">
                            <b>Zapomnij o:</b> długim szukaniu materiałów w sieci i drogich korepetycjach.
                        </span>

                        <div className="main-page__team-section__main__right main-page__team-section__main__right--mobile"> 
                            <img 
                                className="main-page__team-section__main__right__group-photo"
                                src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/grupowe_bez_tla-min_3.png" 
                                alt="">    
                            </img>
                        </div>

                        <div className="main-page__team-section__clickable">
                            <div 
                                className="not-selectable main-page__team-section__clickable__button"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    navigate("/aboutUs");
                                }}
                            >
                                <span>
                                    Poznaj nasz zespół
                                </span>

                                <ArrowGraphic/>
                            </div>

                        </div>

                    </div>

                    <div className="main-page__team-section__main__right main-page__team-section__main__right--desktop"> 
                        <img 
                            className="main-page__team-section__main__right__group-photo"
                            src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/grupowe_bez_tla-min_3.png" 
                            alt="">    
                        </img>
                    </div>
                </div>

            </div>
    )
}