import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotFound } from "../../components/error/NotFound";
import { LessonPreviewNavigation } from "./LessonPreviewNavigation/LessonPreviewNavigation";

import { PreviewVideoPage } from "./PreviewVideoPage/PreviewVideoPage";
import { PreviewNotes } from "./PreviewNotes/PreviewNotes";
import { PreviewAssignment } from "./PreviewAssignment/PreviewAssignment";
import { PreviewQuiz } from "./PreviewQuiz/PreviewQuiz";

import { LessonPreviewDto } from "../../../../shared/lessonPreviewDto";

import "./lessonPreview.scss";

import "../../components/navbar/navbar.scss";

import { UserAnswer } from "../../components/course/additions/quiz/Quiz";
import { Quiz } from "./PreviewQuiz/PreviewQuiz";
import { get } from "../../utilities/rest/apiClient";
import { WideMenu } from "../../components/shared/components/wideMenu/WideMenu";
import { GlobalDataContext } from "../../components/global/globalDataContext/GlobalDataContextProvider";
import { LessonPreviewFallback } from "./LessonPreviewFallback";
import { Logo } from "../../components/navbar/logo/logo";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";

export const PreviewHeader = () => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    return (
        <header className="artificial-navbar">
            
            <div className="artificial-navbar__logo-wrapper" onClick={() => navigate('/')}>
                <Logo/>
            </div>

            <div className="artificial-navbar__menu">
                <div className="artificial-navbar__menu__wrapper">
                    <div className="artificial-navbar__menu__wrapper__button">
                        <span>
                            {t("kurs.nawigacja.mojeKursy")}
                        </span>
                    </div>
                </div>

                <div className="artificial-navbar__menu__language" id="navigation__user-panel__language-selector">
                    <span className="material-symbols-outlined">language</span>
                    <span id="navigation__user-panel__language-selector__language">
                        {i18n.language.toUpperCase()}
                    </span>
                </div>  

                <div className="artificial-navbar__menu__user-panel" id="navigation__user-panel__my-profile">
                    <div id="navigation__user-panel__my-profile__icon-bundle">
                            <img alt="img" src={require("../../components/navbar/userPanel/user-icon-6-gray.png")}/>
                            <span data-testid='main-nav-button' className="material-symbols-outlined">
                                expand_more
                            </span>
                    </div>

                    <div 
                        data-testid='main-nav-button-mobile'
                        id="navigation__user-panel__my-profile__hamburger-menu"
                        className="artificial-navbar__menu__user-panel__main-nav-button-mobile"
                    >
                
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>

            </div>
        </header>
    )
}


type ActiveMaterial = "quiz-button" | "assignments-button" | "notes-button" | "none";


interface LessonPreviewProps {}

export const LessonPreview = (props: LessonPreviewProps) => {
    usePageTracking("lessonPreview");
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { courseId } = useParams();
    
    const [data, setData] = useState<LessonPreviewDto>({} as LessonPreviewDto);
    const [isLoading, setIsLoading] = useState(true);

    const [activeMaterial, setActiveMaterial] = useState<ActiveMaterial>("none");

    const [notesActivated, setNotesActivated] = useState(false);

    const [assignmentsCompleted, setAssignmentsCompleted] = useState(1);
    const [activeAssignment, setActiveAssignment] = useState(0);

    const [quizCompleted, setQuizCompleted]= useState(0);
    const [activeQuiz, setActiveQuiz] = useState(0);
    const [quizAnswersDisplayed, setQuizAnswersDisplayed] = useState(false);
    const [quizUserAnswers, setQuizUserAnswers] = useState<UserAnswer[]>([]);
    const [wrongAnswers, setWrongAnswers] = useState<UserAnswer[]>([]);
    
    const [showAllQuizes, setShowAllQuizes] = useState(true);
    const [quizAllResultIndex, setAllQuizResultIndex] = useState(0);
    const [quizWrongResultIndex, setWrongQuizResultIndex] = useState(0);

    const [resultQuizesSet, setResultQuizesSet] = useState<Quiz[]>([]);
    const [resultUserAnswersSet, setResultUserAnswersSet ] = useState<UserAnswer[]>([]);

    useEffect(() => {
        setIsLoading(true);

        (async ()=> {
            try {
              const loadedData = await get<LessonPreviewDto>(`reactRoutes/getLessonPreview/${courseId}/${i18n.language}`);

              setData(loadedData);
            } catch(error) {
                // TODO: HANDLE ERRORS
            }
        })()
    }, []);

    useEffect(() => {
        if(Object.keys(data).length !== 0) {
            setIsLoading(false);
        }
    }, [data]);


    useEffect(() => {
        const path = window.location.pathname;
        const sufix = "/additions";

        if(path.includes(sufix)) {
            const basePath = path.split(sufix)[0];
            navigate(`${basePath}`);
        }
    }, []);

    useEffect(() => {
        if(activeAssignment+1 > assignmentsCompleted) 
            setAssignmentsCompleted(assignmentsCompleted+1);
    }, [activeAssignment, assignmentsCompleted]);

    useEffect(() => {
        if(activeQuiz+1 > quizCompleted) {
            setQuizCompleted(quizCompleted+1);
        }
    }, [activeQuiz, quizCompleted])


    const checkActiveAddition = () =>  {
        return location.pathname.includes('/additions')
    }

    const handleBackToCoursePage = () => {
        if(courseId) {
            navigate(`/coursePreview/${courseId}`);
        } else navigate('/');
    }

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };

    if(isLoading){ 
        return <LessonPreviewFallback/>
    } return (
        <>
            <PreviewHeader/>
                <div id="content-name" className="content-name-preview-lesson">
                    {
                        checkActiveAddition() && 
                            <span 
                                className="material-symbols-outlined"
                                onClick={() => handleBackToCoursePage() }
                            >
                                arrow_back_ios
                            </span>
                        
                    }                
    

                    {`${t("kurs.kurs")} - ${data.path[0]}`}
                    <div className="preview-data-subtext">
                        <span>
                            {t("kurs.demonstrationLesson.previewLesson")} - <b>1</b> {t("kurs.demonstrationLesson.of")} <b>{data.lessonsCount}</b> {t("kurs.demonstrationLesson.avaiableLessons")}
                        </span>
                    </div>
                </div>

                <div id="content-panel">

                    <div>
                        <WideMenu mainCoursePanel={false} inactive={true}/>    
                    </div>
                    <div id="data-panel-wrapper">
                
                        <div id="data-panel">
                            <div id="data-panel__nav">
                                <span>{data.path[0]}</span>
                                <span className="material-symbols-outlined">keyboard_arrow_right</span>
                                <span>{data.path[1]}</span>
                                <span className="material-symbols-outlined">keyboard_arrow_right</span>
                                <span>{data.path[2]}</span>
                            </div>
                            
                            <Routes>
                                <Route path="" element={<PreviewVideoPage src={data.videoSrc} lessonDescription={data.lessonDescription} courseId={courseId}/>}/>
                                <Route path="additions">
                                    <Route path="quiz" element={
                                        <PreviewQuiz 
                                            quizes={data.quizes}
                                            quizAnswersDisplayed={quizAnswersDisplayed}
                                            setQuizAnswersDisplayed={setQuizAnswersDisplayed}
                                            activeQuiz={activeQuiz}
                                            setActiveQuiz={setActiveQuiz}

                                            quizUserAnswers={quizUserAnswers}
                                            setQuizUserAnswers={setQuizUserAnswers}

                                            showAllQuizes={showAllQuizes}
                                            setShowAllQuizes={setShowAllQuizes}

                                            quizAllResultIndex={quizAllResultIndex}
                                            setAllQuizResultIndex={setAllQuizResultIndex}

                                            quizWrongResultIndex={quizWrongResultIndex}
                                            setWrongQuizResultIndex={setWrongQuizResultIndex}

                                            wrongAnswers={wrongAnswers}
                                            setWrongAnswers={setWrongAnswers}

                                            resultQuizesSet={resultQuizesSet}
                                            setResultQuizesSet={setResultQuizesSet}

                                            resultUserAnswersSet={resultUserAnswersSet}
                                            setResultUserAnswersSet={setResultUserAnswersSet}

                                            setQuizCompleted={setQuizCompleted}
                                        /> 

                                    }/>
                                    <Route path="assignments" element={
                                        <PreviewAssignment 
                                            assignments={data.assignments} 
                                            activeAssignment={activeAssignment} 
                                            setActiveAssignment={setActiveAssignment}
                                            explanationsAvailable={data.explanationsAvailable}
                                        />
                                    }/>
                                    <Route path="notes" element={<PreviewNotes notes={data.notes} setNotesActivated={setNotesActivated}/>}/>
                                    <Route path="" element={<NotFound />} />
                                    <Route path="*" element={ <NotFound /> }/>
                                </Route>
                                <Route path="" element={ <NotFound />} />
                                <Route path="*" element={ <NotFound /> } />
                            </Routes>
                        </div>

                    </div>

                    <LessonPreviewNavigation 
                        data={data} 
                        activeMaterial={activeMaterial} 
                        setActiveMaterial={setActiveMaterial}
                        notesActivated={notesActivated}

                        assignmentsCompleted={assignmentsCompleted}
                        assignmentsCount={data.assignments.length}

                        quizCompleted={quizCompleted}
                        quizesCount={data.quizes.length}

                        scrollToTop={scrollToTop}
                    />


                </div>
        </>
    )
}