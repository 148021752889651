import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./mainPage.scss";

import { DownLeftRay } from "./graphics/DownLeftRay";
import { CheckMark } from "./graphics/CheckMark";
import { Play } from "./graphics/Play";
import { ArrowGraphic } from "./graphics/ArrowGraphic";


import { OfferBoxes } from "./OfferBoxes/OfferBoxes";
import { Carousel } from "./Carousel/Carousel";
import { Opinions } from "./Opinions/Opinions";
import { Features } from "./Features/Features";
import { CourseSellSection } from "./CourseSellSection/CourseSellSection";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { VideoPreview } from "./VideoPreview/VideoPreview";

import { TeamSection } from "./TeamSection/TeamSection";
import { FinalTestPreview } from "./FinalTestPreview/FinalTestPreview";
import { SellBaner } from "./SellBaner/SellBaner";
import { MessengerPanel } from "./Messanger/Messenger";
import { Cookie } from "./Cookie/Cookie";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";





export const MainPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    const ref = useRef<HTMLElement | null>(null);

    const handleScrollToClick = () => {
        if(ref.current !== null) {
            ref.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    usePageTracking("landing");

    return (
        <div className="main-page">

            <LandingNavbar/>

            <SellBaner/>
            
            <div className="main-page__introduction">
                <DownLeftRay/>
                    <img 
                        className="arena"
                        alt="" src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/arena.svg"
                    />
                <div className="main-page__introduction__left-section">
                    <div className="main-page__introduction__left-section__headline">
                        <span className="main-page__introduction__left-section__headline__text-1 desktop">
                            {t("landing.withOurPlatform")}&nbsp;
                        </span>
                        <span className="main-page__introduction__left-section__headline__text-1 mobile">
                           Z Nasesje<b>.com</b>
                        </span>
                        <span className="main-page__introduction__left-section__headline__text-2">
                            {t("landing.fearBeforeSession")}
                        </span>
                    </div>
                    <div className="main-page__introduction__left-section__bullet-points">

                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>

                                <span className="material-symbols-outlined">
                                    🚀
                                    {/* rocket */}
                                </span>
                            </div>
                            <div>
                                <span>{t("landing.bulletPoints.innovativeAndEfficient")}</span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    🧑‍💻
                                    {/* school */}
                                </span>
                            </div>
                            <div>
                                <span>{t("landing.bulletPoints.coursesPrepByExperts")}</span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    {/* function */}
                                    📚
                                </span>
                            </div>
                            <div>
                                <span><b>Matematyka</b>, <b>fizyka</b>, <b>statystyka</b> na studia</span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                🎓
                                    {/* build */}
                                </span>
                            </div>
                            <div>
                                <span>{t("landing.bulletPoints.specializedEnginneringSubjects")}</span>  
                            </div>
                            
                        </div>
                    </div>
                    <div className="main-page__introduction__left-section__buttons">
                        {
                            authContext.loggedIn ? (
                                <div 
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate('/userPanel')
                                    }}
                                >
                                    <span>
                                        {t("landing.goToPlatform")}
                                    </span>
                                </div>
                            ) : (
                                <div 
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => { 
                                        window.scrollTo(0, 0);
                                        navigate('/shop')
                                    }}
                                >
                                    <span>
                                        {t("landing.goToShop")}
                                    </span>
                                </div>
                            )           
                        }
                        <div 
                            className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--right"
                            onClick={ handleScrollToClick }
                        >
                            <span>
                                {t("landing.seeHowWeOperate")}
                            </span>
                            <Play/>
                        </div>
                    </div>
                </div>
                <div  className="main-page__introduction__right-section">
                    <div>

                    </div>
                </div>
            </div>

            <div>
            </div>

            <TeamSection/>
            

            <Carousel/>

            <FinalTestPreview/>

            {/* <Opinions/> */}

            <Features/>


            <OfferBoxes/>

            <div ref={ref as React.Ref<HTMLDivElement>}/>
            <VideoPreview/>

            <CourseSellSection/>

            <div className="main-page__go-to-offers">
                <div className="main-page__go-to-offers__wrapper">
                    <div className="main-page__go-to-offers__wrapper__headline"> 
                        <span>
                            {t("landing.andNowTheBest")}:
                        </span>
                    </div>
                    <div className="main-page__go-to-offers__wrapper__main-text">
                        <span>
                            {t("landing.hoursOfRecordings")}
                            <b>&nbsp;{t("landing.inPriceOfOnly3Hours")}</b>.
                        </span>
                    </div>
                    <div 
                        className="main-page__go-to-offers__wrapper__button yellow-button"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate("/shop")
                        }}
                    >
                        <span>
                            {t("landing.findYourFirstCourse")}
                        </span>
                        <ArrowGraphic/>
                    </div>
                </div>
            </div>

            <MessengerPanel/>
            <Cookie/>            

            <LandingFooter/>

        </div>
    );
}