
import { Dispatch, SetStateAction } from "react";

import { DisplayedCourseContents } from "../DisplayedCourseContents/DisplayedCourseContents";
import { DisplayedCourseDescription } from "../DisplayedCourseDescription/DisplayedCourseDescription";
import { DisplayedCourseRecommendation } from "../DisplayedRecomendation/DisplayedRecomendation";
import { Block } from "../../../../../shared/block";
import { useTranslation } from "react-i18next";

interface DisplayedOptionProps {
    text: string,
    myIndex: number,
    activeSectionIndex: number,
    setActiveSectionIndex: Dispatch<SetStateAction<number>>
}

const DisplayedOption = (props: DisplayedOptionProps) => {

    const { myIndex, activeSectionIndex } = props;
    
    const handleClassName = () => {
        const base = "course-preview__main-section__left-panel__panels-selector__option";
        let finalClass = `not-selectable ${base} ${base}--${(myIndex === activeSectionIndex) ? "active" : "inactive"}`;

        return finalClass;
    }

    return (
        <div 
            onClick={() => { if(myIndex !== activeSectionIndex) props.setActiveSectionIndex(myIndex) } } 
            className={handleClassName()}
        >
            <span>{props.text}</span>
        </div>
    );
}  

interface CourseContentWrapperProps {
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[];

    activeSectionIndex: number;
    setActiveSectionIndex: Dispatch<SetStateAction<number>>;
    description: Block[];
    courseRecomendation: Block[];
}

export const CourseSectionsWrapper = (props: CourseContentWrapperProps) => {

    const { activeSectionIndex, setActiveSectionIndex } = props;
    const { t } = useTranslation();

    const handleDisplayedData = () => {

        // console.log(props.courseRecomendation);

        switch (activeSectionIndex) {
            case 0:
                return <DisplayedCourseDescription description={props.description}/>;
            case 1:
                return <DisplayedCourseContents courseContent={props.courseContent}/>;
            case 2:
                return <DisplayedCourseRecommendation courseRecomendation={props.courseRecomendation}/>;
        }
    }

    return (

        <>
            <div className="course-preview__main-section__left-panel__panels-selector">
                {
                    [
                        t("kurs.preview.previewPage.courseDescription"),
                        t("kurs.preview.previewPage.contents"),
                        t("kurs.preview.previewPage.forWho")
                    ].map((el, index) => 
                        <DisplayedOption 
                            key={index} 
                            myIndex={index} 
                            activeSectionIndex={activeSectionIndex} 
                            setActiveSectionIndex={setActiveSectionIndex} 
                            text={el}
                        />)
                }
            </div>

            {
                handleDisplayedData()
            }

        </>
    )
}