
import { useContext } from "react";
import ReactGA from "react-ga4";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";

export const registerEvent = (authContext: any, category: string, action: string, label: string) => {

  if (authContext.allowedCookies.fromBrowser && authContext.allowedCookies.statCookies) {
      if(ReactGA) {
        ReactGA.event({
          category,
          action,
          label,
        });
      }
    }
  };